import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "button"]
  static classes = ["hidden"]

  connect() {
    console.log("Hello from Realtor Apply Button")
    this.class = this.hasHiddenClass ? this.hiddenClass : "hidden"
  }
  
  change(e) {
    if (e.target.value == 'realtor') {
      this.buttonTargets.forEach((item) => {
        item.classList.remove('hidden')
      })
    } else {
      this.buttonTargets.forEach((item) => {
        item.classList.add('hidden')
      })
    }
  }
}
